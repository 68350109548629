import React, { useState, useEffect } from 'react';
import Select, { SingleValue } from 'react-select';
import { VictoryChart, VictoryLine, VictoryAxis, VictoryVoronoiContainer, VictoryTooltip } from 'victory';

interface DataPoint {
  x: Date;
  y: number;
}

interface APIDataPoint {
  datetime: string;
  value_ETH: string;
  value_EUR: number;
  value_USD: number;
}

interface ChartComponentProps {
  talentName: string;
}

const ChartComponent: React.FC<ChartComponentProps> = ({ talentName }) => {
  const [timeframe, setTimeframe] = useState('week');
  const [data, setData] = useState<DataPoint[]>([]);
  const [currency, setCurrency] = useState(localStorage.getItem('pxlCurrency') || 'USD');

  useEffect(() => {
    fetch(`https://peaxel.me/wp-json/custom/v1/get-talent-data/?talent_index=${talentName}&timeframe=${timeframe}`)
      .then((response) => response.json())
      .then((apiData: APIDataPoint[]) => {
        const validData = apiData.map((d) => {
          const date = new Date(d.datetime);
          let value: number;

          switch (currency) {
            case 'EUR':
              value = parseFloat(d.value_EUR.toFixed(2));
              break;
            case 'USD':
              value = parseFloat(d.value_USD.toFixed(2));
              break;
            case 'ETH':
              value = parseFloat((parseFloat(d.value_ETH)).toFixed(6)); // Assuming value_ETH is in wei
              break;
            default:
              value = parseFloat(d.value_USD.toFixed(2));
          }

          return {
            x: date,
            y: value
          };
        }).filter((d) => !isNaN(d.x.getTime()) && !isNaN(d.y));

        console.log('dataV', validData);

        setData(validData);
      });
  }, [timeframe, talentName, currency]);

  const handleCurrencyChange = (selectedOption: SingleValue<{ value: string; label: string }>) => {
    if (selectedOption) {
      setCurrency(selectedOption.value);
    }
  };

  const handleTimeframeChange = (selectedOption: SingleValue<{ value: string; label: string }>) => {
    if (selectedOption) {
      setTimeframe(selectedOption.value);
    }
  };

  const timeframeOptions = [
    { value: '24h', label: '24 Hours' },
    { value: 'week', label: 'Last Week' },
    { value: 'month', label: 'Last Month' },
    { value: 'year', label: 'Last Year' },
    { value: 'all', label: 'All Time' }
  ];

  const currencyOptions = [
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' },
    { value: 'ETH', label: 'ETH' }
  ];

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: '#000000',
      color: '#D8D8D8',
      borderColor: '#343434',
      boxShadow: 'none',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#D8D8D8',
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: '#000000',
      color: '#D8D8D8',
    }),
    option: (provided: any) => ({
      ...provided,
      backgroundColor: '#000000',
      color: '#D8D8D8',
      '&:hover': {
        backgroundColor: '#333333',
      },
    }),
  };

  const yMin = Math.min(...data.map(d => d.y)) * 0.99;
  const yMax = Math.max(...data.map(d => d.y)) * 1.01;

  return (
    <div style={{ padding: '0px', backgroundColor: '#000000', color: '#D8D8D8' }}>
      <VictoryChart
        width={1000}
        domain={{ y: [yMin, yMax] }}
        containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) => `${datum.y}${currency}\u2009\u2009\n${datum.x.toLocaleDateString()} ${datum.x.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
              labelComponent={
                <VictoryTooltip 
                  style={[
                    { fontSize: 18, fontWeight: 'bold', fill: '#FFFFFF', textAnchor: 'start' },
                    { fontSize: 14, fontWeight: 'normal', fill: '#FAFAFA', textAnchor: 'start' }
                  ]}
                  flyoutStyle={{ textAlign: 'left', fill: '#202703', padding: '5px'  }}
                />
              }
            />
          }
      >
        <VictoryLine
          data={data}
          style={{
            data: { stroke: "#C2DB2E", strokeWidth: 2 },
          }}
          interpolation="natural"
        />
        <VictoryAxis
          style={{
            axis: { stroke: "none" },
            ticks: { stroke: "none" },
            tickLabels: { fill: "none" },
            grid: { stroke: "none" }
          }}
          tickFormat={(tick) => `${tick}`}
        />
        <VictoryAxis
          style={{
            axis: { stroke: "none" },
            ticks: { stroke: "none" },
            tickLabels: { fill: "#000000", fontSize: 12 },
            grid: { stroke: "none" }
          }}
          tickFormat={(tick) => {
            const date = new Date(tick);
            return date.getHours() === 0 ? date.toLocaleDateString() : date.getHours();
          }}
        />
        {data.map((d, index) => {
          const date = new Date(d.x);
          if (date.getHours() === 0 && date.getMinutes() === 0) {
            return (
              <VictoryLine
                key={index}
                data={[{ x: d.x, y: yMin }, { x: d.x, y: yMax }]}
                style={{ data: { stroke: "#212121", strokeWidth: 1 } }}
              />
            );
          }
          return null;
        })}
      </VictoryChart>

      <div className="valueChartSelector">
        <div className="chartSelector">
          <Select
            value={currencyOptions.find(option => option.value === currency)}
            onChange={handleCurrencyChange}
            options={currencyOptions}
            styles={customStyles}
          />
        </div>
        <div className="chartSelector">
          <Select
            value={timeframeOptions.find(option => option.value === timeframe)}
            onChange={handleTimeframeChange}
            options={timeframeOptions}
            styles={customStyles}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartComponent;
