
import './App.css'; // Import your CSS file for the modal styles
import { AssetResponse, TalentCard, Order, Asset } from './types';
import React, { useEffect, useState, useCallback, useMemo, Suspense, lazy, memo } from 'react';
import ChartComponent from './ChartComponent';

const TalentDescriptionLazy = memo(lazy(() => import('./TalentDescription')));

interface PageViewModalProps {
  isVisible: boolean;
  onClose: () => void;
  asset: Asset | null;
  dispCurrency: string | null;
  handleProfileButtonClick: (talentName: string, toDisplay: string) => void;
}

const PageViewModal: React.FC<PageViewModalProps> = ({
  isVisible,
  onClose,
  asset,
  dispCurrency,
  handleProfileButtonClick,
}) => {
  const [displayContent, setDisplayContent] = useState<string>('');

  useEffect(() => {
    if (asset) {
      let content = '';

      if (dispCurrency === 'USD') {
        const value = parseFloat(asset.talentCard.USD_value).toFixed(2);
        const ratio = parseFloat(asset.talentCard.USD_ratio).toFixed(2);
        const ratioClass = parseFloat(asset.talentCard.USD_ratio) > 0 ? 'green-text' : 'red-text';

        content = `<div style="color: #FAFAFA; width: 100%; font-weight: 400; font-size: 120%; padding-top: 20px; padding-bottom: 20px;">
          $<b>${value}</b>&nbsp;&nbsp;<span class="${ratioClass}" style="margin-right:15px;">${ratio}%</span>
        </div>`;
      } else if (dispCurrency === 'EUR') {
        const value = parseFloat(asset.talentCard.EUR_value).toFixed(2);
        const ratio = parseFloat(asset.talentCard.EUR_ratio).toFixed(2);
        const ratioClass = parseFloat(asset.talentCard.EUR_ratio) > 0 ? 'green-text' : 'red-text';

        content = `<div style="color: #FAFAFA; width: 100%; font-weight: 400; font-size: 120%; padding-top: 20px; padding-bottom: 20px;">
          <b>${value}</b>€&nbsp;&nbsp;<span class="${ratioClass}" style="margin-right:15px;">${ratio}%</span>
        </div>`;
      }

      setDisplayContent(content);
    }
  }, [asset, dispCurrency]);

  const handleFirstButtonClick = () => {
    if (asset?.name) {
      handleProfileButtonClick(asset?.name, 'buyoffers');
    }
  };

  const handleSecondButtonClick = () => {
    if (asset?.name) {
      handleProfileButtonClick(asset?.name, 'selloffers');
    }
  };

  console.log('asset',asset);

  if (!isVisible) {
    return null;
  }

  const main_activity_html = asset?.talentCard.main_activity
  ? `<span class="tagTalentClass">
        <b>${asset.talentCard.main_activity}</b>
      </span>`
    : '';
  
  const main_category_html = asset?.talentCard.main_category
    ? `<span class="tagTalentClass">
          <b>${asset.talentCard.main_category}</b>
        </span>`
      : '';
 

  const nationClub_html = asset?.talentCard.national_team
    ? `<span class="tagTalentClass">
        ${asset.talentCard.national_team_url
          ? `<img src="${asset.talentCard.national_team_url}" alt="National Team" style="max-height: 12px; border-radius:2px;">&nbsp;`
          : ''}
        <b>${asset.talentCard.national_team}</b>
      </span>`
    : '';

  const leagueClub_html = asset?.talentCard.league
    ? `<span class="tagTalentClass">
        ${asset.talentCard.league_url
          ? `<img src="${asset.talentCard.league_url}" alt="League" style="max-height: 12px; border-radius:2px;">&nbsp;`
          : ''}
        <b>${asset.talentCard.league}</b>
      </span>`
    : '';

  const nameClub_html = asset?.talentCard.club
    ? `<span class="tagTalentClass">
        ${asset.talentCard.club_url
          ? `<img src="${asset.talentCard.club_url}" alt="Club" style="max-height: 12px; border-radius:2px;">&nbsp;`
          : ''}
        <b>${asset.talentCard.club}</b>
      </span>`
    : '';

  const tags_html = [0, 1, 2, 3, 4, 5]
    .map((i) => {
      const tag = asset?.talentCard[`tag${i}` as keyof TalentCard];
      return tag ? `<span class="prizeTalentClass2">${tag}</span>` : '';
    })
    .join('');

  const prizes_html = [1, 2, 3, 4, 5]
    .map((i) => {
      const prize = asset?.talentCard[`prize${i}` as keyof TalentCard];
      return prize ? `<span class="prizeTalentClass2">${prize}</span>` : '';
    })
    .join('');

    const formatDescription = (description: string) => {
      return description
        .split('\n\n')
        .map(paragraph => `<p>${paragraph.replace(/\n/g, '<br>')}</p>`)
        .join('');
    };

    const formattedDescription = asset?.talentCard.talent_description
  ? formatDescription(asset.talentCard.talent_description)
  : '';

  return (
    <div className="talent-modal">
      <div className="stickyMenuPVM">
        <div onClick={onClose} className="closeButtonPageViewPVM">
          <i className="fa-solid fa-chevron-left"></i> Back
        </div>
        <div className="profile-info-buttons">
          <div
            id="talentAverageValue"
            dangerouslySetInnerHTML={{ __html: displayContent }}
          ></div>
          <div className="sell-button" onClick={handleSecondButtonClick}>
            Collected Card<span id="seeMyCards"></span>
          </div>
          <div className="buy-button" onClick={handleFirstButtonClick}>
            Buy Card<span id="buyMyCards"></span>
          </div>
        </div>
      </div>
      <div className="modal-content-PVM">
        <div className="product-details">
          <div className="product-image-columnPVM">
            {/* Display a picture here */}
            <img src={asset?.talentCard.profile_new_url} alt="Profile" className="profilePicturePVM" />
          </div>
          <div className="product-info-column">
            
            <span className='crypto-index'>{asset?.talentCard.talent_name_original}</span>
          
            <div className='blockquote-target'>
            {asset?.talentCard.talent_first_name}&nbsp;{asset?.talentCard.talent_last_name}
            <span style={{ fontSize: '70%', fontWeight: 200, opacity: 0.8 }}>
            &nbsp;&nbsp;•&nbsp;&nbsp;{asset?.talentCard.talent_age}yo
            </span>
            <span style={{ fontSize: '70%', fontWeight: 200, opacity: 0.8 }}>
            &nbsp;&nbsp;•&nbsp;&nbsp;{asset?.talentCard.main_nationality}
            </span>
            </div>
            <div className="ps-3rdline">
              <span className="classTalentTags" id="talentTags" dangerouslySetInnerHTML={{ __html: `${main_activity_html}${main_category_html}` }}></span>
              <span className="classTalentTags" id="infoClub" dangerouslySetInnerHTML={{ __html: `${nationClub_html}${leagueClub_html}${nameClub_html}` }}></span>
            </div>
            <div className="ps-3rdline">
              <span className="classTalentTags" id="talentPrizes" dangerouslySetInnerHTML={{ __html: `${tags_html}${prizes_html}` }}></span>
            </div>
            
            <div className="ps-4thline">
      <span className="force">
        <i style={{ fontSize: '70%', position: 'relative', top: '-1px' }} className="fa-solid fa-shield-halved"></i>
        &nbsp;{asset?.talentCard.talent_force}&nbsp;&nbsp;
        <span style={{ fontWeight: 300, fontSize: '110%' }}>Force</span>
      </span>
      <span className="XP">
        <i style={{ fontSize: '70%', position: 'relative', top: '-1px' }} className="fa-solid fa-bolt"></i>
        &nbsp;{asset?.talentCard.talent_experience}&nbsp;&nbsp;
        <span style={{ fontWeight: 300, fontSize: '110%' }}>Experience</span>
      </span>
      <span className="insta_prof">
        <i style={{ fontSize: '70%', position: 'relative', top: '-1px' }} className="fa-brands fa-instagram"></i>
        &nbsp;{asset?.talentCard.instagram_followers}
      </span>
      <span className="managers-count">
        <i style={{ fontSize: '70%', position: 'relative', top: '-1px' }} className="fa-solid fa-chess-rook"></i>
        &nbsp;
        <span style={{ fontWeight: 300, fontSize: '100%' }} id="managersCount"></span>
      </span>
    </div>

  {asset?.talentCard.talent_description && (
  <div className="modal-div">
    <h2>About {asset.talentCard.talent_first_name}</h2>
    <div className="info-tab">
      <Suspense fallback={<div>Loading...</div>}>
        <TalentDescriptionLazy description={formattedDescription} truncated />
      </Suspense>
    </div>

    <div className="info-tab">
    
    {asset?.talentCard.talent_instagram && (
  <span
    className="classTalentTags"
    style={{
      background: '#171415',
      padding: '6px 20px',
      borderRadius: '5px',
      fontWeight: 100,
      fontSize: '90%',
      marginRight: '10px',
    }}
  >
    <a
      href={`https://www.instagram.com/${asset.talentCard.talent_instagram}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa-brands fa-instagram"></i> {asset.talentCard.talent_instagram}
    </a>
  </span>
)}

{asset?.talentCard.talent_youTube && (
  <span
    className="classTalentTags"
    style={{
      background: '#171415',
      padding: '6px 20px',
      borderRadius: '5px',
      fontWeight: 100,
      fontSize: '90%',
      marginRight: '10px',
    }}
  >
    <a
      href={`https://www.youtube.com/${asset.talentCard.talent_youTube}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa-brands fa-youtube"></i> {asset.talentCard.talent_youTube}
    </a>
  </span>
)}



{asset?.talentCard.talent_tiktok && (
  <span
    className="classTalentTags"
    style={{
      background: '#171415',
      padding: '6px 20px',
      borderRadius: '5px',
      fontWeight: 100,
      fontSize: '90%',
      marginRight: '10px',
    }}
  >
    <a
      href={`https://www.tiktok.com/@${asset.talentCard.talent_tiktok}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa-brands fa-tiktok"></i> {asset.talentCard.talent_tiktok}
    </a>
  </span>
)}

{asset?.talentCard.talent_facebook && (
  <span
    className="classTalentTags"
    style={{
      background: '#171415',
      padding: '6px 20px',
      borderRadius: '5px',
      fontWeight: 100,
      fontSize: '90%',
      marginRight: '10px',
    }}
  >
    <a
      href={`https://www.facebook.com/${asset.talentCard.talent_facebook}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa-brands fa-facebook"></i> {asset.talentCard.talent_facebook}
    </a>
  </span>
)}

{asset?.talentCard.talent_twitch && (
  <span
    className="classTalentTags"
    style={{
      background: '#171415',
      padding: '6px 20px',
      borderRadius: '5px',
      fontWeight: 100,
      fontSize: '90%',
      marginRight: '10px',
    }}
  >
    <a
      href={`https://m.twitch.tv/${asset.talentCard.talent_twitch}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa-brands fa-twitch"></i> {asset.talentCard.talent_twitch}
    </a>
  </span>
)}



{asset?.talentCard.talent_linkedIn && (
  <span
    className="classTalentTags"
    style={{
      background: '#171415',
      padding: '6px 20px',
      borderRadius: '5px',
      fontWeight: 100,
      fontSize: '90%',
      marginRight: '10px',
    }}
  >
    <a
      href={`https://fr.linkedin.com/in/${asset.talentCard.talent_linkedIn}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa-brands fa-linkedin"></i> {asset.talentCard.talent_linkedIn}
    </a>
  </span>
)}

{asset?.talentCard.talent_pinterest && (
  <span
    className="classTalentTags"
    style={{
      background: '#171415',
      padding: '6px 20px',
      borderRadius: '5px',
      fontWeight: 100,
      fontSize: '90%',
      marginRight: '10px',
    }}
  >
    <a
      href={`https://www.pinterest.com/${asset.talentCard.talent_pinterest}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa-brands fa-pinterest"></i> {asset.talentCard.talent_pinterest}
    </a>
  </span>
)}



    </div>





  </div>
)}


<div className="modal-div">
    <h2>Average Value of Cards</h2>
    <div className="info-tab">
{asset?.name && (
<ChartComponent talentName={asset.name} />
)}
</div>
</div>

<div className="modal-div">
    <h2>Weekly Performances</h2>
    <div className="info-tab">

</div>
</div>
  
          </div>
        </div>
      </div>

      <div className="stickyMenuPVMBottom">
        <div className="profile-info-buttons">
          <div className="sell-button-bottom" onClick={handleSecondButtonClick}>
            Collected Card<span id="seeMyCardsBottom"></span>
          </div>
          <div className="buy-button-bottom" onClick={handleFirstButtonClick}>
            Buy Card<span id="buyMyCardsBottom"></span>
          </div>
        </div>
      </div>
    
    </div>
  );
};

export default PageViewModal;
